import React from "react"
import Modal from "react-bootstrap/Modal"
import parse from "html-react-parser"
import styled from "styled-components"

const StyledTable = styled.table`
  td:first-child {
    white-space: nowrap;
  }

  td {
    padding-right: 1em;
    padding-bottom: 1em;
    vertical-align: top;

    @media (max-width: ${props => props.theme.sizes.xs}) {
      display: block;
      padding-bottom: 0;
      padding-right: 0;

      &:last-child {
        padding-bottom: 1em;
      }
    }
  }

  tr {
  }
`

export default function CvModal({ handleClose, show, cv }) {
  return (
    <Modal show={show} onHide={handleClose} centered>
      <Modal.Header closeButton>
        <Modal.Title></Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <h3>Arbeiten</h3>
        <StyledTable>
          <tbody>
            {cv.arbeiten.map((row, index) => (
              <tr key={`jsnajkbd${index}`}>
                <td>{row.jahr}</td>
                <td dangerouslySetInnerHTML={{ __html: row.tatigkeit }}></td>
              </tr>
            ))}
          </tbody>
        </StyledTable>

        <h3 className="mt-5">Bildung</h3>
        <StyledTable>
          <tbody>
            {cv.bildung.map((row, index) => (
              <tr key={`adfsdfs${index}`}>
                <td>{row.jahr}</td>
                <td dangerouslySetInnerHTML={{ __html: row.tatigkeit }}></td>
              </tr>
            ))}
          </tbody>
        </StyledTable>
      </Modal.Body>
    </Modal>
  )
}
