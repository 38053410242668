import React, { useState } from "react"
import { Helmet } from "react-helmet"
import styled from "styled-components"
import { graphql } from "gatsby"
import CvModal from "../components/CvModal"

const StyledDiv = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  flex: 1 1 100%;
  position: relative;

  h3 {
    a {
      &:hover {
        text-decoration: underline;
      }
    }
  }
`

const StyledPortrait = styled.div`
  width: 220px;
  height: 220px;
  border-radius: 100%;
  overflow: hidden;
  margin-bottom: 2rem;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
`

const StyledCollab = styled.a`
  border: 3px solid white;
  display: flex;
  padding: 7px;
  padding-bottom: 5px;
  transform: rotate(-8deg);
  position: absolute;
  top: 0;
  left: 30px;

  @media (max-width: ${props => props.theme.sizes.sm}) {
    top: auto;
    bottom: 0;
    left: 0;
    position: relative;
  }

  &:hover {
    background-color: white;

    p {
      color: var(--bg-blue);
    }

    img {
      filter: invert(75%);
    }
  }

  img {
    width: 55px;
    height: auto;
    margin-left: 15px;
  }
`

const StyledCVLink = styled.span`
  cursor: pointer;

  &:hover {
    text-decoration: underline;
  }
`

export default function KontaktPage({ data }) {
  const page = data.wpPage
  console.log(page)

  const [show, setShow] = useState(false)

  const handleClose = () => setShow(false)
  const handleShow = () => setShow(true)

  return (
    <StyledDiv>
      <Helmet>
        <title>Kontakt – Thierry Seiler Webseiten und Webapps, Zürich</title>
        <meta
          property="og:title"
          content="Thierry Seiler Webseiten und Webapps, Zürich"
        />
        <meta
          property="og:description"
          content="Ich bin dein Partnerfür das Programmieren von Webseiten & Webapps"
        />
      </Helmet>
      <div className="container d-flex flex-column align-items-center justify-content-center mb-8">
        <StyledPortrait>
          <img src="/portrait3_bearbeitet.jpg" alt="portrait" />
        </StyledPortrait>
        <h3 className="text-center mb-3">Thierry Seiler</h3>
        <h4 className="text-center  mb-6">
          BA Interaction Design, ZHdK
          <br />
           CAS Frontend Engineering, HSR
          <br />
          <StyledCVLink onClick={() => handleShow()}>➜ curriculum</StyledCVLink>
        </h4>
        <h3 className="text-center">
          <a href="tel:0442234742">Tel: 044 223 47 42</a>
          <br />
          <a href="mailto:mail@thierryseiler.ch">mail@thierryseiler.ch</a>
        </h3>
        {/* <StyledCollab
          href="https://www.fint.ch"
          target="_blank"
          className="my-12 my-md-0"
        >
          <p className="mb-0">
            In Zusammenarbeit
            <br /> mit Fint
          </p>
          <img src="/fint.svg" alt="Fint Logo" />
        </StyledCollab> */}
      </div>
      <CvModal handleClose={handleClose} show={show} cv={page.cv} />
    </StyledDiv>
  )
}

export const pageQuery = graphql`
  query AllPagData {
    wpPage(id: { eq: "cG9zdDoy" }) {
      cv {
        arbeiten {
          jahr
          tatigkeit
        }
        bildung {
          jahr
          tatigkeit
        }
      }
    }
  }
`
